import React, { useReducer, useState } from 'react';
import classes from './CFOptionModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import cross2 from '../assets/candleflower-modal-img/white.png';
import Button from '../components/UI/Button/Button';

const cfImages = [
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f4_rfzvva.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f3_peytxr.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f2_cfvspa.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f1_uxle4u.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497662/c4_zytr81.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c3_zdjbnk.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497656/c2_kcjjwc.png',
  'https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c1_bsfofz.png',
];
const initialState = [
  {
    id: 1,
    type: 'flower',
    count: 0,
  },
  {
    id: 2,
    type: 'flower',
    count: 0,
  },
  {
    id: 3,
    type: 'flower',
    count: 0,
  },
  {
    id: 4,
    type: 'flower',
    count: 0,
  },
  {
    id: 5,
    type: 'candle',
    count: 0,
  },
  {
    id: 6,
    type: 'candle',
    count: 0,
  },
  {
    id: 7,
    type: 'candle',
    count: 0,
  },
  {
    id: 8,
    type: 'candle',
    count: 0,
  },
];

const reducer = (state, action) => {
  switch (action.type) {
    case 'INCREASE_ITEM':
      return state.map((item) =>
        item.id === action.payload ? { ...item, count: item.count + 1 } : item
      );
    case 'DECREASE_ITEM':
      return state.map((item) =>
        item.id === action.payload
          ? { ...item, count: Math.max(0, item.count - 1) }
          : item
      );
    case 'REMOVE_ITEM':
      return state.map((item) =>
        item.id === action.payload ? { ...item, count: 0 } : item
      );

    case 'RESET_ITEMS':
      return state.map((item) => ({ ...item, count: 0 }));
    default:
      return state;
  }
};

const CFOptionModal = ({ handleFormSubmit }) => {
  const [candleFlowerState, dispatch] = useReducer(reducer, initialState);
  const [dedication, setDedication] = useState('');
  const totalPrice = candleFlowerState.reduce(
    (acc, item) => acc + item.count * 5,
    0
  );

  //total candle
  const totalCandle = candleFlowerState.reduce(
    (acc, item) => (item.type === 'candle' ? acc + item.count : acc),
    0
  );

  return (
    <React.Fragment>
      <div
        className="modal fade qr-modal"
        id="cfOption"
        tabIndex="-1"
        aria-labelledby="cfOptionLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className={`modal-content text-center ${classes.modal_content}`}>
            <div
              className={`modal-header justify-content-between ${classes.modal_header}`}
            >
              <div className="m-auto">
                <h5
                  className={`${classes.modal_title} m-auto`}
                  id="cfOptionLabel"
                >
                  הדלקת נר / הנחת פרח
                </h5>
                <h6 className={`${classes.modal_sub_title} m-auto d-block`}>
                  עלות נר / פרח הינה 5 ש"ח וניתן לבחור יותר מבחירה אחת
                </h6>
              </div>
              <button
                type="button"
                className={`${classes.btn_close}  btn align-self-start`}
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img src={cross} alt="Close" />
              </button>
            </div>

            <div className={`modal-body ${classes.modal_body}`}>
              <div className="container">
                <div className="row">
                  <div>
                    <h3>בחרו פרח:</h3>
                    <hr className={classes.title_hr} />
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f1_uxle4u.png"
                        alt="flower"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 4 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f2_cfvspa.png"
                        alt="flower"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 3 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497659/f3_peytxr.png"
                        alt="flower"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 2 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497661/f4_rfzvva.png"
                        alt="flower"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 1 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div>
                    <h3>בחרו נר:</h3>
                    <hr className={classes.title_hr} />
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c1_bsfofz.png"
                        alt="candle"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 8 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497656/c2_kcjjwc.png"
                        alt="candle"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 7 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497657/c3_zdjbnk.png"
                        alt="candle"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 6 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                  <div className="col-3">
                    <div>
                      <img
                        src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677497662/c4_zytr81.png"
                        alt="candle"
                        className={classes.cf_image}
                      />
                    </div>
                    <button
                      className={classes.add_button}
                      onClick={() =>
                        dispatch({ type: 'INCREASE_ITEM', payload: 5 })
                      }
                    >
                      בחירה
                    </button>
                  </div>
                </div>
                <hr />
                <div className={classes.dedicated_texts}>
                  <h3 className="my-3">הוסיפו הקדשה אישית</h3>
                  <input
                    type="text"
                    placeholder="כתבו הקדשה ליקירכם או למשפחה"
                    className="form-control"
                    onChange={(e) => setDedication(e.target.value)}
                    value={dedication}
                  />
                </div>

                <p className={classes.amount_title}>סיכום: {totalPrice} ש״ח</p>
                <form
                  className="container"
                  onSubmit={(e) => {
                    dispatch({ type: 'RESET' });
                    handleFormSubmit(e, candleFlowerState, dedication);
                  }}
                >
                  {candleFlowerState
                    .filter((cf) => cf.count > 0)
                    .map((cf, index) => {
                      return (
                        <div
                          className="d-flex justify-content-between align-items-center mb-4"
                          index={index}
                          key={index}
                        >
                          <div
                            className={`d-flex justify-content-between text-white align-items-center px-3 rounded-3 w-100 ${classes.counter}`}
                          >
                            <div className="d-flex justify-content-center align-items-center gap-3">
                              <div>
                                <img src={cfImages[cf.id - 1]} alt={cf.type} />
                              </div>

                              <div className={classes.increase_decrease}>
                                <button
                                  className="pointer"
                                  type="button"
                                  onClick={() =>
                                    dispatch({
                                      type: 'DECREASE_ITEM',
                                      payload: cf.id,
                                    })
                                  }
                                >
                                  -
                                </button>
                                <span className="mx-1">{cf.count}</span>
                                <button
                                  className="pointer"
                                  type="button"
                                  onClick={() =>
                                    dispatch({
                                      type: 'INCREASE_ITEM',
                                      payload: cf.id,
                                    })
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                            <div>
                              <h5 className="m-0" dir="rtl">
                                {cf.type === 'candle' ? 'נר' : 'פרח'} מס׳{' '}
                                {cf.id >= 5 ? cf.id - 4 : cf.id} |{' '}
                                {cf.count * 5}₪
                              </h5>
                            </div>
                          </div>
                          <button
                            type="button"
                            className={classes.btn_close_sm}
                            onClick={() =>
                              dispatch({ type: 'REMOVE_ITEM', payload: cf.id })
                            }
                          >
                            <img src={cross2} alt="Close" />
                          </button>
                        </div>
                      );
                    })}
                  <Button
                    className={` my-4 ${classes.submit_btn}`}
                    type="submit"
                  >
                    לרכישה
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CFOptionModal;
