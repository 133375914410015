import React from 'react';
import classes from './DemoProfile.module.css';
import demoProfile01 from '../../assets/home-img/demo01.png';
import demoProfile02 from '../../assets/home-img/demo02.png';
import demoProfile03 from '../../assets/home-img/demo03.jpg';
import demoProfile04 from '../../assets/home-img/demo04.png';
import mobileDemoProfile01 from '../../assets/home-img/mobileDemo01.png';
import mobileDemoProfile02 from '../../assets/home-img/mobileDemo02.png';
import mobileDemoProfile03 from '../../assets/home-img/mobileDemo03.png';
import newProfileImage from '../../assets/home-img/newProfile.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper';
import Section from '../layout/Section';
import { Link } from 'react-router-dom';

const DemoProfile = () => {
  return (
    <Section
      className={classes.demo_profile}
      bg="#dcecf4"
      gap={false}
      padding
      showCloud={false}
    >
      <div className="container">
        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className={`demoSwiper ${classes.swiper} d-md-block d-none`}
        >
          {/* <SwiperSlide className="d-md-none d-block">
            <img src={newProfileImage} alt="newProfileImage" />
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div className={`${classes.image_container}`}>
              <img src={mobileDemoProfile01} alt="Demo Profile" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${classes.image_container}`}>
              <img src={mobileDemoProfile02} alt="Demo Profile" />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className={`${classes.image_container}`}>
              <img src={mobileDemoProfile03} alt="Demo Profile" />
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <a
              className={classes.image_container}
              href="https://lifecloud-qr.com/profile/629227f40fc791cf90ac1844"
              target="_blank"
              rel="noreferrer"
            >
              <img src={demoProfile01} alt="newProfileImage" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className={classes.image_container}
              href="https://lifecloud-qr.com/profile/62930e650fc791cf90ac210c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={demoProfile02} alt="newProfileImage" />
            </a>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className={classes.image_container}>
              <img src={demoProfile03} alt="newProfileImage" />
            </div>
          </SwiperSlide> */}
          <SwiperSlide>
            <a
              className={classes.image_container}
              href="https://lifecloud-qr.com/profile/62ad94c2c2c4ad0b0ed6f8d5"
              target="_blank"
              rel="noreferrer"
            >
              <img src={demoProfile04} alt="newProfileImage" />
            </a>
          </SwiperSlide>
        </Swiper>

        <Swiper
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation]}
          navigation={true}
          className={`demoSwiper ${classes.swiper} d-md-none d-block`}
        >
          <SwiperSlide>
            <a
              className={`${classes.image_container}`}
              href="https://lifecloud-qr.com/profile/629227f40fc791cf90ac1844"
              target="_blank"
              rel="noreferrer"
            >
              <img src={mobileDemoProfile01} alt="Demo Profile" />
            </a>
          </SwiperSlide>
          <SwiperSlide>
            <a
              className={`${classes.image_container}`}
              href="https://lifecloud-qr.com/profile/62930e650fc791cf90ac210c"
              target="_blank"
              rel="noreferrer"
            >
              <img src={mobileDemoProfile02} alt="Demo Profile" />
            </a>
          </SwiperSlide>
          {/* <SwiperSlide>
            <div className={`${classes.image_container}`}>
              <img src={mobileDemoProfile03} alt="Demo Profile" />
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </Section>
  );
};

export default DemoProfile;
