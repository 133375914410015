import React from 'react';
import Button from '../UI/Button/Button';
import classes from './TributeCandleFlower.module.css';

const TributeCandleFlower = () => {
  return (
    <section className={classes.tribute}>
      <div className="container">
        <div className="row">
          {/* <h2 className="profile_title">טיפות של זכרון</h2> */}
          <div
            className={`text-center d-flex justify-content-center ${classes.button_container}`}
          >
            <Button data-bs-toggle="modal" data-bs-target="#candleFlower">
              הדלקת נר וירטואלי
            </Button>
            <Button data-bs-toggle="modal" data-bs-target="#candleFlower">
              הנחת פרח וירטואלי
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TributeCandleFlower;
