import React from 'react';
import classes from './SocialLogin.module.css';
import { useDispatch } from 'react-redux';
import { userLoginWithSocialMedia } from '../../features/user/userSlice';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import toastCreator from '../../utils/toastifyCreator';
import { useLocation, useNavigate } from 'react-router-dom';
import google from '../../assets/registration-img/loginGoogle.png';
import facebook from '../../assets/registration-img/loginFacebook.png';
import Button from '../UI/Button/Button';
import { hideModal } from '../../features/modal/modalSlice';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const SocialLogin = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    dispatch(hideModal('loginModal'));
    dispatch(hideModal('registrationModal'));
    navigate(location.pathname);
  };

  const responseFacebook = (response) => {
    const [firstName, lastName] = response.name?.split(' ');
    const formData = {
      email: response.email,
      firstName,
      lastName,
      profilePicture: response.picture.data.url,
    };
    dispatch(userLoginWithSocialMedia({ formData, showNotification }));
  };
  const handleFailure = (response) => {
    return toastCreator(
      'error',
      'Something went wrong, please try again later'
    );
  };

  const responseGoogle = async (token) => {
    const response = await axios(
      'https://www.googleapis.com/oauth2/v2/userinfo',
      {
        headers: {
          Authorization: `Bearer ${token.access_token}`,
        },
      }
    );
    const {
      email,
      given_name: firstName,
      family_name: lastName,
      picture: profilePicture,
    } = response.data;
    const formData = { email, firstName, lastName, profilePicture };
    dispatch(userLoginWithSocialMedia({ formData, showNotification }));
  };
  const login = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: handleFailure,
  });

  return (
    <section className={classes.social_login}>
      <Button
        className={`${classes.social_btn} w-100 mb-3 mb-md-0`}
        onClick={() => login()}
      >
        <img src={google} alt="Google Login" className="me-2" /> חשבון גוגל
      </Button>
      <FacebookLogin
        appId="521682099843747"
        callback={responseFacebook}
        onFailure={handleFailure}
        fields="name,email,picture"
        render={(renderProps) => (
          <Button
            className={`${classes.social_btn}  w-100`}
            onClick={renderProps.onClick}
          >
            <img src={facebook} alt="Facebook Login" className="me-2" />
            חשבון פייסבוק
          </Button>
        )}
        disableMobileRedirect={true}
      />
    </section>
  );
};

export default SocialLogin;
