import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputError from '../../components/InputError/InputError';
import { useDispatch, useSelector } from 'react-redux';
import { createCandleFlower } from '../../features/candleFlower/candleFlowerSlice';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import lifeAxios from '../../utils/lifeAxios';
import { createNotification } from '../../features/notification/notificationSlice';
import Swal from 'sweetalert2';

const LightCF = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  //Form validation schema
  const schema = yup.object().shape({
    user: yup.string().required('User ID is required'),
    profile: yup.string().required('User ID is required'),
    candles: yup.number(),
    flowers: yup.number(),
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  //Form  submit handler
  const onSubmit = async (formData) => {
    if (user.role !== 'admin')
      return alert(
        'You are not Admin, So please pay 💵 add light up candle and flower ☠'
      );
    dispatch(createCandleFlower(formData));

    const profileResponse = await lifeAxios(localStorage.getItem('jwt')).get(
      `/profiles/${formData.profile}`
    );
    const profile = profileResponse.data.data.data;

    const creatorResponse = await lifeAxios(localStorage.getItem('jwt')).get(
      `/users/${formData.user}`
    );
    const creator = creatorResponse.data.data.data;
    const receivers = [
      profile.originalUser._id,
      ...profile.profileAdmins.map((admin) => admin._id),
      ...profile.profileFriends.map((friend) => friend._id),
    ];

    const notification = {
      ...emailTextAndSubject(
        'candleFlower',
        {
          organizationName: profile.organizationName,
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        {
          organizationName: creator.organizationName,
          firstName: creator.firstName,
          lastName: creator.lastName,
        }
      ),
      creator: creator._id,
      receivers: receivers,
      url: `https://lifecloud-qr.com/profile/${formData.profile}`,
    };
    await dispatch(createNotification(notification));

    alert('Candle and Flower added successfully');
  };

  const handleClick = () => {
    Swal.fire({
      title: 'Are you sure you want to light up candle and flower?',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off',
        placeholder: `Type '確認' to proceed!`,
      },
      showCancelButton: true,
      confirmButtonText: 'Yes',
      showLoaderOnConfirm: true,
      preConfirm: (text) => {
        if (text !== '確認' || text === '')
          Swal.showValidationMessage(`Please type '確認' to proceed!`);

        return lifeAxios(localStorage.getItem('jwt'))
          .get(`/candleFlowers/light-cf`)
          .then((response) => {
            if (!response.statusText === 'OK') {
              throw new Error(response.statusText);
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire('Candle and Flower has been lit up!');
      }
    });
  };

  return (
    <div>
      <h2
        style={{
          color: 'crimson',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          width: '100%',
          height: '100vh',
          fontSize: '6rem',
          fontFamily: 'cursive',
        }}
      >
        Currently the feature is unavailable. Contact with the developer
      </h2>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="container bg-light shadow p-5 my-5"
      >
        <div className="mb-3">
          <label htmlFor="dedication" className="form-label">
            Dedication Text
          </label>
          <input
            type="text"
            className="form-control"
            id="dedication"
            {...register('dedication')}
          />
          <InputError error={errors.dedication?.message} />
        </div>
        <div className="mb-3">
          <label htmlFor="profile" className="form-label">
            Profile Id
          </label>
          <input
            type="text"
            className="form-control"
            id="profile"
            {...register('profile')}
          />
          <InputError error={errors.profile?.message} />
        </div>
        <div className="mb-3">
          <label htmlFor="dedication" className="form-label">
            Dedication Text
          </label>
          <input
            type="text"
            className="form-control"
            id="dedication"
            {...register('dedication')}
          />
          <InputError error={errors.dedication?.message} />
        </div>
        <div className="mb-3">
          <label htmlFor="user" className="form-label">
            User Id
          </label>
          <input
            type="text"
            className="form-control"
            id="user"
            {...register('user')}
          />
          <InputError error={errors.user?.message} />
        </div>

        <div className="mb-3">
          <label htmlFor="candles" className="form-label">
            Candles
          </label>

          <input
            type="number"
            className="form-control"
            id="candles"
            {...register('candles')}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="flowers" className="form-label">
            Flowers
          </label>
          <input
            type="number"
            className="form-control"
            id="flowers"
            {...register('flowers')}
          />
        </div>
        <button className="btn btn-primary btn-lg">Submit</button>
      </form>
      {/* <div className="text-center">
        <button
          className="btn btn-danger btn-lg"
          style={{ fontSize: '3rem', padding: '2rem 3rem' }}
          onClick={handleClick}
        >
          LIGHT CANDLE AND FLOWER <i className="fas fa-lightbulb"></i>
        </button>
      </div> */}
    </div>
  );
};

export default LightCF;
