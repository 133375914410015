import React from 'react';
import { ToastContainer } from 'react-toastify';
import RegistrationModal from '../../modals/RegistrationModal';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';
import classes from './MainLayout.module.css';
const MainLayout = ({
  children,
  gap,
  bg = '#dcecf4',
  className,
  style,
  header = true,
  footerClassName,
  ...rest
}) => {
  return (
    <React.Fragment>
      {header && <Header />}
      <main
        style={{ backgroundColor: bg, ...style }}
        className={`${gap && classes.page_gap} ${className}`}
        {...rest}
      >
        {children}
        <ToastContainer theme="colored" />
      </main>
      <Footer className={footerClassName} />
      <RegistrationModal />
      <a
        className={classes.sticky_button}
        href="https://api.whatsapp.com/send?phone=+972532814019&text=%D7%A9%D7%9C%D7%95%D7%9D,%20%D7%90%D7%A9%D7%9E%D7%97%20%D7%9C%D7%A7%D7%91%D7%9C%20%D7%A4%D7%A8%D7%98%D7%99%D7%9D%20%D7%A0%D7%95%D7%A1%D7%A4%D7%99%D7%9D%20%E2%98%81%EF%B8%8F"
      >
        <img
          src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675755802/image_26_kpkirk.png"
          alt="WhatsApp"
        />
      </a>
    </React.Fragment>
  );
};

export default MainLayout;
