import React, { useState } from 'react';
import Button from '../UI/Button/Button';
import classes from './Gallery.module.css';
import Section from '../layout/Section';
import ReactPlayer from 'react-player';
import getFileType from '../../utils/getFileType';
import Slider from 'react-slick';
import FsLightbox from 'fslightbox-react-premium';
import GalleryGrid from './GalleryGrid';

const Gallery = ({ gallery }) => {
  const [toggler, setToggler] = useState(false);
  const [index, setIndex] = useState(0);
  const [showGallery, setShowGallery] = useState(false);
  const handleGalleryClick = (index) => {
    setToggler(!toggler);
    setIndex(index);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          initialSlide: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <Section
      className={`${classes.gallery} position-relative text-center`}
      padding
      gap={false}
      colorful
      cloudPos="down-left"
      id="gallery"
    >
      <div className="container">
        {showGallery ? (
          <GalleryGrid gallery={gallery} setShowGallery={setShowGallery} />
        ) : (
          <>
            <h2 className="profile_title text-center">גלריה</h2>
            {gallery.length > 0 ? (
              <React.Fragment>
                <div className="px-5">
                  <Slider {...settings}>
                    {gallery.map((image, index) => (
                      <div className={classes.gallery_item} key={index}>
                        {getFileType(image) === 'image' ? (
                          <img
                            src={image}
                            alt="gallery"
                            className={classes.media}
                            onClick={() => handleGalleryClick(index)}
                          />
                        ) : (
                          <div
                            className={classes.media}
                            onClick={() => handleGalleryClick(index)}
                          >
                            <ReactPlayer
                              url={image}
                              controls
                              width="100%"
                              height="100%"
                            />
                          </div>
                        )}
                      </div>
                    ))}
                  </Slider>
                </div>
                <div className={`profile_button_group ${classes.button_group}`}>
                  <Button outline={true} onClick={() => setShowGallery(true)}>
                    לכל הגלריה
                  </Button>
                </div>
              </React.Fragment>
            ) : (
              <h5 className="not_found" dir="rtl">
                עוד לא הועלתה מדיה לפרופיל ☁
              </h5>
            )}
          </>
        )}
      </div>
      <FsLightbox
        toggler={toggler}
        sources={gallery}
        sourceIndex={index}
        type="image"
        types={gallery.map((image) => getFileType(image))}
      />
    </Section>
  );
};
function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      src="https://i.ibb.co/QbsPhds/arrow-right.png"
      className={className}
      onClick={onClick}
      alt="arrow-right"
    />
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <img
      src="https://i.ibb.co/xXhx2hr/arrow-left.png"
      className={className}
      onClick={onClick}
      alt="arrow-left"
    />
  );
}
export default Gallery;
