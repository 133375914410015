import React from 'react';
import { useForm } from 'react-hook-form';
import classes from '../Form.module.css';
import arrow2 from '../../assets/registration-img/arrow2.png';
import Button from '../../components/UI/Button/Button';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InputError from '../../components/InputError/InputError';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { createContact } from '../../features/contact/contactSlice';
import alertCreator from '../../utils/alertCreator';
import { Helmet } from 'react-helmet-async';

//Form validation schema
const schema = yup.object().shape({
  firstName: yup.string().required('!שם פרטי הינו שדה חובה*'),
  lastName: yup.string().required('!שם משפחה הינו שדה חובה*'),
  phone: yup.string().required('!טלפון הינו שדה חובה*'),

  email: yup
    .string()
    .email('!המייל שהוזן אינו תקין*')
    .required('!מייל הינו שדה חובה*'),
  message: yup.string().required('!נדרשת הודעה*'),
});

const Contact = () => {
  const { loading } = useSelector((state) => state.contact);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ resolver: yupResolver(schema) });

  const showNotification = () => {
    alertCreator({
      icon: 'success',
      title: 'תודה על פנייתכם,\n נחזור אליכם בהקדם האפשרי :)',
    });
  };
  //Form  submit handler
  const onSubmit = async (formData) => {
    dispatch(createContact({ formData, showNotification }));
    reset();
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | צרו קשר</title>
        <meta property="og:title" content="LifeCloud | צרו קשר" />
        <meta property="og:url" content="https://lifecloud-qr.com/contact" />
        <meta
          name="description"
          content="לפניות או עזרה עם לייף קלאוד, צרו קשר. אנחנו כאן לסיוע אישי בכל הקשור להנצחה הדיגיטלית בלייף קלאוד - מוזמנים ליצור קשר על ידי מילוי הטופס, מייל, טלפונית או בווצאפ."
        />

        <meta
          property="og:description"
          content="לפניות או עזרה עם לייף קלאוד, צרו קשר. אנחנו כאן לסיוע אישי בכל הקשור להנצחה הדיגיטלית בלייף קלאוד - מוזמנים ליצור קשר על ידי מילוי הטופס, מייל, טלפונית או בווצאפ."
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <section className={classes.contact}>
          {/* <img src={cloud} alt="" className={classes.contact_cloud} /> */}
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-10 mx-auto">
                <h2 className={`page_title`}>צרו קשר</h2>
                <form className="text-center" onSubmit={handleSubmit(onSubmit)}>
                  <div className={`row gx-md-5`}>
                    <div className="col-6">
                      <div className={classes.input_container}>
                        <input
                          {...register('firstName')}
                          placeholder="*שם משפחה..."
                          className={`form-control ${classes.input}`}
                        />

                        <InputError error={errors.firstName?.message} />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className={classes.input_container}>
                        <input
                          {...register('lastName')}
                          placeholder="*שם פרטי..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.lastName?.message} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="tel"
                          {...register('phone')}
                          placeholder="*טלפון..."
                          className={`form-control ${classes.input}`}
                        />
                        <InputError error={errors.phone?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className={classes.input_container}>
                        <input
                          type="email"
                          {...register('email')}
                          placeholder="*מייל..."
                          className={`form-control ${classes.input}`}
                        />

                        <InputError error={errors.email?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className={classes.input_container}>
                        <textarea
                          {...register('message')}
                          placeholder="*טקסט חופשי..."
                          className={`form-control ${classes.input} ${classes.textarea}`}
                        ></textarea>

                        <InputError error={errors.message?.message} />
                      </div>
                    </div>

                    <div className="col-12">
                      <Button
                        className={`w-100 ${classes.submit_btn}`}
                        outline={false}
                        loading={loading}
                      >
                        שליחה
                      </Button>
                      <img
                        src={arrow2}
                        alt="Arrow"
                        className={`d-block ${classes.arrow}`}
                      />
                    </div>
                  </div>
                </form>
                <div className={` ${classes.contact_bottom_text}`}>
                  <address className="d-none d-sm-block" dir="rtl">
                    כתובת: רחוב השדה 101, כפר חיטים | מייל:{' '}
                    <a
                      href="mailto:office@lifecloud-qr.com"
                      className={classes.address}
                    >
                      office@lifecloud-qr.com
                    </a>{' '}
                    | מס' טלפון:{' '}
                    <a href="tel:+97252-3753750" className={classes.address}>
                      052-3753750
                    </a>
                  </address>
                  <address className="d-block d-sm-none" dir="rtl">
                    רחוב השדה 101, כפר חיטים
                    <br />
                    <a
                      href="mailto:office@lifecloud-qr.com"
                      className={classes.address}
                    >
                      office@lifecloud-qr.com
                    </a>
                    <br />
                    <a href="tel:+052-3753750" className={classes.address}>
                      052-3753750
                    </a>
                  </address>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export default Contact;
