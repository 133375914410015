import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HashLink } from 'react-router-hash-link';
import { showModal } from '../../features/modal/modalSlice';
import CFOptionModal from '../../modals/CFOptionModal';
import classes from './CFWidget.module.css';

const CFWidget = () => {
  const { candlesFlowers } = useSelector((state) => state.candleFlower);
  const dispatch = useDispatch();

  const total = candlesFlowers
    .flatMap((obj) => obj.candlesFlowers)
    .reduce((acc, curr) => acc + curr.count, 0);

  const { user } = useSelector((state) => state.user);
  const {
    profile: { profileId },
  } = useSelector((state) => state.profile);

  const handleFormSubmit = (event, candleFlowerState, dedication) => {
    event.preventDefault();
    if (!user._id) return dispatch(showModal('loginModal'));

    const totalPrice = candleFlowerState.reduce(
      (acc, item) => acc + item.count * 5,
      0
    );
    localStorage.setItem('profileId', profileId);

    const queryString = encodeURIComponent(
      JSON.stringify(candleFlowerState.filter((cf) => cf.count > 0))
    );

    window.location.assign(
      `https://direct.tranzila.com/icloud/iframenew.php?supplier=icloud&template=custom&ppnewwin=2&u71=1&candlesFlowers=${queryString}&sum=${totalPrice}&currency=1&contact=${
        user.organizationName || user.firstName
      }&email=${user.email}&user=${
        user._id
      }&tranmode=A&pdesc=CandleOrFlower&type_code=320&tamal_lang=il&profile=${profileId}&doc_remark=docremark&dedication=${dedication}`
    );
  };
  return (
    <React.Fragment>
      <div
        className={`d-flex align-items-center ${classes.widget_container}`}
        role="button"
      >
        <button
          className={classes.widget_left}
          data-bs-toggle="modal"
          data-bs-target="#cfOption"
        >
          הדלק נר ו/או הנח פרח!
        </button>
        <div className={classes.widget_right}>
          <img
            src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1677295372/candle_02_g22yft.jpg"
            alt="Candle/Flower"
            data-bs-toggle="modal"
            data-bs-target="#cfOption"
          />
          <HashLink to="#virtual-memory">
            <div
              className={`d-flex justify-content-center align-items-center ${classes.cf_count}`}
            >
              <span>+{total}</span>
            </div>
          </HashLink>
        </div>
      </div>{' '}
      <CFOptionModal handleFormSubmit={handleFormSubmit} />
    </React.Fragment>
  );
};

export default CFWidget;
