import React from 'react';
import DemoProfile from '../../components/DemoProfile/DemoProfile';
import MainLayout from '../../components/layout/MainLayout';
import Remember from '../../components/Remember/Remember';
import Review from '../../components/Review/Review';
import SocialFooter from '../../components/SocialFooter/SocialFooter';
import Action from '../../components/Action/Action';
import LifeStory from '../../components/LifeStory/LifeStory';
import Hero from '../../components/Hero/Hero';
import HeroSearch from '../../components/HeroSearch/HeroSearch';
import { Helmet } from 'react-helmet-async';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { showModal } from '../../features/modal/modalSlice';
import OrganizationSlider from '../../components/OrganizationSlider/OrganizationSlider';
const Home = () => {
  const query = Object.fromEntries(new URLSearchParams(window.location.search));
  const dispatch = useDispatch();
  useEffect(() => {
    if (query.reg) {
      dispatch(showModal('registrationModal'));
    }
  }, [dispatch, query.reg]);
  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | בית חדש לחיים שאחרי</title>
        <meta property="og:url" content="https://lifecloud-qr.com/" />
      </Helmet>
      <MainLayout bg="#fff">
        <Hero />
        <HeroSearch />
        <Remember />
        <LifeStory />
        <DemoProfile />
        <Action bg="#1A4E74" to="/create-profile">
          !לחץ ליצירת פרופיל חדש – ללא עלות
        </Action>

        <Review />
        <OrganizationSlider />
        <SocialFooter cloudPos="down-left" />
      </MainLayout>
    </React.Fragment>
  );
};

export default Home;
