import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';
/* Initial State */
const initialState = {
  loading: true,
  user: {},
  error: null,
  tokenError: null,
};
/* It will store jwt token in the local storage */
const storeJwt = (jwt) => {
  localStorage.setItem('jwt', jwt);
};
/* It will remove jwt token from the local storage */
const removeJwt = () => {
  localStorage.removeItem('jwt');
};
/* Signup User */
export const userSignup = createAsyncThunk(
  'user/signup',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/signup`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Login User */
export const userLogin = createAsyncThunk(
  'user/login',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/login`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Login User */
export const userLoginWithSocialMedia = createAsyncThunk(
  'user/loginWithSocialMedia',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/loginWithSocialMedia`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Logout User */
export const userLogout = createAsyncThunk(
  'user/logout',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/users/logout`
      );
      if (response.data.status === 'success') removeJwt();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Get the current login user */
export const getMe = createAsyncThunk('user/me', async () => {
  try {
    const response = await lifeAxios(localStorage.getItem('jwt')).get(
      `/users/me`
    );
    return response.data.data.data;
  } catch (error) {
    return error.response.data;
  }
});
export const updateMe = createAsyncThunk('user/updateMe', async (formData) => {
  try {
    const response = await lifeAxios(localStorage.getItem('jwt')).patch(
      `/users/updateMe`,
      formData
    );
    return response.data.data.user;
  } catch (error) {
    return error.response.data;
  }
});
/* Verify Email */
export const verifyEmail = createAsyncThunk(
  'user/verifyEmail',
  async (token, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/users/verifyEmail/${token}`
      );
      return response.data.data.user;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Forgot Password */
export const forgotPassword = createAsyncThunk(
  'user/forgotPassword',
  async ({ formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/users/forgotPassword`,
        formData
      );
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* Reset Password */
export const resetPassword = createAsyncThunk(
  'user/resetPassword',
  async ({ token, formData, showNotification }, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/users/resetPassword/${token}`,
        formData
      );
      if (response.data.status === 'success') storeJwt(response.data.token);
      showNotification('success');
      return response.data.data.user;
    } catch (error) {
      showNotification('error', error.response.data.message);
      return rejectWithValue(error.response.data.message);
    }
  }
);
/* User Reducer */
const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    /* If email verify failed */
    builder.addCase(verifyEmail.rejected, (state, action) => {
      state.tokenError = action.payload;
    });
    /* Logout User */
    builder.addCase(userLogout.fulfilled, (state) => {
      state.user = {};
      state.loading = false;
      state.error = null;
      state.tokenError = null;
    });
    builder.addMatcher(
      isAnyOf(
        userSignup.pending,
        userLogin.pending,
        userLoginWithSocialMedia.pending,
        verifyEmail.pending,
        forgotPassword.pending,
        resetPassword.pending,
        getMe.pending,
        updateMe.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        userSignup.fulfilled,
        userLogin.fulfilled,
        userLoginWithSocialMedia.fulfilled,
        verifyEmail.fulfilled,
        forgotPassword.fulfilled,
        resetPassword.fulfilled,
        getMe.fulfilled,
        updateMe.fulfilled
      ),
      (state, action) => {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
      }
    );
    builder.addMatcher(
      isAnyOf(
        userSignup.rejected,
        userLogin.rejected,
        userLoginWithSocialMedia.rejected,
        verifyEmail.rejected,
        forgotPassword.rejected,
        resetPassword.rejected,
        getMe.rejected,
        updateMe.rejected
      ),
      (state, action) => {
        state.loading = false;
        state.user = {};
        state.error = action.payload;
      }
    );
  },
});

export default userSlice.reducer;
