import React, { useState } from 'react';
import Section from '../layout/Section';
import Button from '../UI/Button/Button';
import classes from './AllProfiles.module.css';
import { Link } from 'react-router-dom';
import QrModalTest from '../../modals/QrModalTest';
import { showModal } from '../../features/modal/modalSlice';
import { useDispatch } from 'react-redux';

const AllProfiles = ({ profiles }) => {
  const dispatch = useDispatch();
  const [selectedProfile, setSelectedProfile] = useState(null);
  return (
    <Section className={classes.users_profile} showCloud={false}>
      <div className="container">
        <h2 className="profile_title">הפרופילים שלי</h2>
        <div className="row gx-sm-5 gy-5 justify-content-center">
          {profiles.map((profile) => (
            <div className="col-lg-3 col-md-4 col-6" key={profile._id}>
              <div className={`${classes.users_profile_item} text-center`}>
                <Link
                  to={`/profile/${
                    profile._id
                  }/${profile.firstName?.trim()}-${profile.lastName?.trim()}`}
                >
                  <div className="ratio ratio-1x1">
                    <img
                      src={profile.profileImg}
                      alt="user"
                      className="img-fluid rounded-circle"
                    />
                  </div>
                  <h3>
                    {profile.firstName} {profile.lastName}
                  </h3>
                </Link>
                <Link to={`/edit-profile/${profile._id}`}>
                  <Button>עריכת פרופיל</Button>
                </Link>

                <Button
                  className="mt-4 px-5"
                  onClick={() => {
                    setSelectedProfile(profile);
                    dispatch(showModal('qrFirstModal'));
                  }}
                  dir="rtl"
                >
                  רכישת QR
                </Button>
              </div>
            </div>
          ))}
          <div className="col-lg-3 col-md-4 col-6">
            <div
              className={`${classes.icon_container} ratio ratio-1x1 rounded-circle`}
            >
              <Link to="/create-profile">
                <div className="d-flex justify-content-center align-items-center w-100 h-100">
                  <div className={classes.plus}>
                    <div className={`${classes.line}`}></div>
                    <div className={`${classes.line}`}></div>
                  </div>
                </div>
              </Link>
            </div>
            <h3>צור פרופיל חדש</h3>
          </div>
        </div>
      </div>

      {selectedProfile && <QrModalTest profileId={selectedProfile._id} />}
    </Section>
  );
};

export default AllProfiles;
