import React, { useEffect, useState } from 'react';
import classes from './CreateMemory.module.css';
import formClasses from '../Form.module.css';
import Button from '../../components/UI/Button/Button';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { createMemory } from '../../features/memory/memorySlice';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useNavigate, useParams } from 'react-router-dom';
import toastCreator from '../../utils/toastifyCreator';
import alertCreator from '../../utils/alertCreator';
import InputError from '../../components/InputError/InputError';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import { createNotification } from '../../features/notification/notificationSlice';
import { getProfile } from '../../features/profile/profileSlice';
import { Helmet } from 'react-helmet-async';
//Form validation schema
const schema = yup.object().shape({
  description: yup.string().required('סיפור הזיכרון הינו חובה!'),
  media: yup.mixed(),
});
const CreateMemory = () => {
  const { profileId } = useParams();

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | העלאת זכרון חדש</title>
        <meta
          property="og:url"
          content={`https://lifecloud-qr.com/create-memory/${profileId}`}
        />
      </Helmet>
      <CenterContentLayout cloudPos="right-left">
        <CreateMemoryBody profileId={profileId} />
      </CenterContentLayout>
    </React.Fragment>
  );
};

export const CreateMemoryBody = ({ profileId }) => {
  const navigate = useNavigate();
  const [media, setMedia] = useState(null);
  const { user } = useSelector((state) => state.user);
  const { loading } = useSelector((state) => state.memory);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const { profile } = useSelector((state) => state.profile);

  /* useEffect(() => {
    dispatch(getProfile(profileId));
  }, [dispatch, profileId]); */

  const showNotification = (status, payload) => {
    if (status === 'error') return toastCreator('error', payload);
    const notification = {
      ...emailTextAndSubject(
        'memoryCreation',
        {
          firstName: profile.firstName,
          lastName: profile.lastName,
        },
        {
          organizationName: user.organizationName,
          firstName: user.firstName,
          lastName: user.lastName,
        }
      ),
      creator: user._id,
      receivers: [
        profile.originalUser._id,
        ...profile.profileAdmins.map((admin) => admin._id),
        ...profile.profileFriends.map((friend) => friend._id),
      ],
      url: `https://lifecloud-qr.com/memory/${payload}`,
    };
    dispatch(createNotification(notification));
    alertCreator({
      icon: 'success',
      title: 'הזיכרון נשמר, שתפו אותו עם חברים',
    }).then(() => {
      let modal = window.bootstrap.Modal.getOrCreateInstance(
        document.getElementById('createMemoryModal')
      );
      modal.hide();
      navigate(`/memory/${payload}`);
    });
  };

  const onSubmit = ({ description, media, title }) => {
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('profile', profileId);
    formData.append('originalUser', user._id);
    if (media[0]) formData.append('media', media[0]);

    dispatch(createMemory({ formData, showNotification }));
  };
  const onChangeMedia = (event, setImg) => {
    if (event.target.files[0]) {
      const media = event.target.files[0];
      if (media.type.startsWith('video'))
        alertCreator({
          icon: 'info',
          title:
            'הסרטון שבחרת כבד וטעינתו תיקח זמן מה, יש להשאר בעמוד עד לסיום ההעלאה',
        });
      const src = media.type.startsWith('video')
        ? 'https://res.cloudinary.com/lifecloud-qr/image/upload/v1672574174/photo-1611162616475-46b635cb6868_l2gzrj.avif'
        : URL.createObjectURL(media);
      setMedia(src);
    }
  };
  return (
    <section className={classes.create_memory}>
      {/* <h2>העלאת זכרון חדש</h2> */}
      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto">
            <form onSubmit={handleSubmit(onSubmit)} className="text-center">
              <div className="d-flex justify-content-center">
                <div className={classes.social}>
                  <label htmlFor="media">
                    {media ? (
                      <img
                        src={media}
                        alt="upload"
                        className={classes.preview_img}
                      />
                    ) : (
                      <div className={classes.img_container}>
                        <div className={classes.plus}>
                          <div className={`${classes.line}`}></div>
                          <div className={`${classes.line}`}></div>
                        </div>
                      </div>
                    )}
                    בחרו תמונה/סרטון/מצגת להעלאה
                    <input
                      type="file"
                      id="media"
                      name="media"
                      accept="image/*, video/*,.pptx"
                      className="d-none"
                      {...register('media', {
                        onChange: onChangeMedia,
                      })}
                    />
                  </label>
                </div>
              </div>
              <div className={`row gx-5 ${classes.input_container}`}>
                <div className="col-12">
                  <input
                    type="text"
                    className={`form-control ${formClasses.input} ${classes.input}`}
                    placeholder="שם הזכרון..."
                    {...register('title')}
                    maxLength="15"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <textarea
                    {...register('description')}
                    className={`form-control ${formClasses.input} ${formClasses.textarea} ${classes.textarea}`}
                    placeholder="תוכן הזכרון..."
                    dir="rtl"
                  ></textarea>
                  <InputError error={errors.description?.message} />
                </div>
              </div>

              <div className={`row gy-4 gy-md-5 ${classes.button_container}`}>
                <Button className={`${classes.button} w-100`} loading={loading}>
                  פרסם
                </Button>
                <Link to={`/profile/${profileId}`}>
                  <Button className={`${classes.button} w-100`} type="button">
                    ביטול
                  </Button>
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CreateMemory;
