import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classes from './SearchShow.module.css';
const SearchShow = ({ searchValue, className, setSearchValue }) => {
  const { profiles, profileLoading } = useSelector((state) => state.profile);

  return (
    <React.Fragment>
      {searchValue && (
        <div className={`${classes.search_results} ${className}`}>
          {profileLoading ? (
            <p className={classes.no_results}>Loading...</p>
          ) : (
            <React.Fragment>
              {profiles.length > 0 ? (
                <React.Fragment>
                  {profiles.slice(0, 5).map((profile) => (
                    <Link
                      onClick={() => setSearchValue('')}
                      to={
                        profile.isOrganization
                          ? `/organization-profile/${profile._id}`
                          : `/profile/${profile._id}`
                      }
                      className={classes.search_result}
                      key={profile._id}
                    >
                      <div>
                        <img src={profile.profileImg} alt="profile" />
                      </div>
                      <p className="text-end">
                        {profile.organizationName} {profile.firstName}{' '}
                        {profile.lastName}
                      </p>
                    </Link>
                  ))}
                  <Link
                    to={`search/${searchValue}`}
                    className={classes.view_all}
                    onClick={() => setSearchValue('')}
                  >
                    פרופילים נוספים
                  </Link>
                </React.Fragment>
              ) : (
                <p className={classes.no_results}>לא נמצאו תוצאות</p>
              )}
            </React.Fragment>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default SearchShow;
