import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginModal: false,
  registrationModal: false,
  qrFirstModal: false,
  qrSecondModal: false,
  forgotPasswordModal: false,
  memorialModal: false,
  addressModal: false,
  dedicationModal: false,
  notificationModal: false,
  teaserVideoModal: false,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, action) => {
      for (const key in state) {
        state[key] = false;
      }

      state[action.payload] = true;
    },
    hideModal: (state, action) => {
      for (const key in state) {
        state[key] = false;
      }
      state[action.payload] = false;
    },
  },
});
export default modalSlice.reducer;
export const { showModal, hideModal } = modalSlice.actions;
