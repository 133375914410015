import React, { useState } from 'react';
import Button from '../../components/UI/Button/Button';
import { BsArrowLeft } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import classes from './Notification.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import {
  createNotification,
  getMyNotifications,
  makeFrNfViewed,
  makeNotificationsViewed,
} from '../../features/notification/notificationSlice';
import CenterContentLayout from '../../components/layout/CenterContentLayout';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import emailTextAndSubject from '../../utils/emailTextAndSubject';
import {
  addOrganizationUser,
  removeOrganizationUser,
} from '../../features/organizationProfile/organizationProfileSlice';
import { addUser, removeUser } from '../../features/profile/profileSlice';
import toastCreator from '../../utils/toastifyCreator';
import lifeAxios from '../../utils/lifeAxios';
import { Accordion } from 'react-bootstrap';
import { hideModal } from '../../features/modal/modalSlice';
const Notification = () => {
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet>
        <title>LifeCloud | התראות</title>
        <meta
          property="og:url"
          content="https://lifecloud-qr.com/notifications"
        />
      </Helmet>
      <CenterContentLayout gap={true} cloudPos="right-left">
        <section className={classes.notification}>
          <div className="container">
            <div className={classes.notification_header}>
              <h2>התראות</h2>
            </div>
            <NotificationBody />
          </div>
          <div className="mt-5">
            <Button type="button" onClick={() => navigate(-1)}>
              <BsArrowLeft className="me-2" />
              חזרה
            </Button>
          </div>
        </section>
      </CenterContentLayout>
    </React.Fragment>
  );
};

export const NotificationBody = ({ className, modal }) => {
  let page = 1;
  const dispatch = useDispatch();

  const [notificationsViewed, setNotificationsViewed] = useState([]);
  const [viewedNotifications, setViewedNotifications] = useState([]);
  const [unViewedNotifications, setUnViewedNotifications] = useState([]);
  const [totalViewedNf, setTotalViewedNf] = useState(0);

  useEffect(() => {
    (async () => {
      if (modal) {
        const response = await lifeAxios(localStorage.getItem('jwt')).get(
          `/notifications/my-notifications?page=1&limit=3`
        );
        const notifications = response.data.data.data;

        setViewedNotifications(notifications.filter((nf) => nf.viewed));
        setUnViewedNotifications(notifications.filter((nf) => !nf.viewed));
      } else {
        const promise1 = lifeAxios(localStorage.getItem('jwt')).get(
          `/notifications/my-notifications?page=1&limit=5&viewed=true`
        );
        const promise2 = lifeAxios(localStorage.getItem('jwt')).get(
          `/notifications/my-notifications?viewed=false`
        );
        const [viewed, unViewed] = await Promise.all([promise1, promise2]);
        setTotalViewedNf(viewed.data.results);
        setUnViewedNotifications(unViewed.data.data.data);
        setViewedNotifications(viewed.data.data.data);
      }
    })();
  }, [modal]);
  const fetchMoreData = async () => {
    page += 1;
    const response = await lifeAxios(localStorage.getItem('jwt')).get(
      `/notifications/my-notifications?page=${page}&limit=5&viewed=true`
    );
    setViewedNotifications((prev) => [...prev, ...response.data.data.data]);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(makeNotificationsViewed());
    }, 2000);
    return () => clearTimeout(timeout);
  }, [dispatch]);

  const showNotification = (status, payload) => toastCreator(status, payload);
  const addUserToProfile = async (userId, profile, field) => {
    setNotificationsViewed((prev) => [...prev, profile._id]);
    const formData = { userId, field, profileId: profile._id };
    const formData2 = {
      userId,
      field: 'friendRequests',
      profileId: profile._id,
    };

    if (profile.isOrganization) {
      await dispatch(addOrganizationUser({ formData, showNotification }));
      dispatch(
        removeOrganizationUser({ formData: formData2, showNotification })
      );
    } else {
      await dispatch(addUser({ formData, showNotification }));
      dispatch(removeUser({ formData: formData2, showNotification }));
    }
    const notification = {
      ...emailTextAndSubject(field, {
        organizationName: profile.organizationName,
        firstName: profile.firstName,
        lastName: profile.lastName,
      }),
      creator: profile.originalUser._id,
      receivers: [userId],
      url: window.location.href,
    };
    dispatch(makeFrNfViewed(profile._id));
    dispatch(createNotification(notification));
  };
  const removeUserToProfile = async (userId, profile, field) => {
    setNotificationsViewed((prev) => [...prev, profile._id]);
    const formData = { userId, field, profileId: profile._id };
    if (profile.isOrganization) {
      await dispatch(removeOrganizationUser({ formData, showNotification }));
    } else {
      await dispatch(removeUser({ formData, showNotification }));
    }
    dispatch(makeFrNfViewed(profile._id));
  };

  const viewedNfJsx = (
    <div className={classes.pillbox}>
      {viewedNotifications.map((notification) => (
        <a href={notification.url} key={notification._id}>
          <div
            className={`d-flex justify-content-between align-items-center gap-md-5 gap-3 ${classes.notification_container}`}
            style={{ background: '#1A4E74' }}
          >
            <div className="d-flex ps-md-2 ps-3 justify-content-center align-items-center">
              <img
                src={notification.creator?.profilePicture}
                alt="Notification Creator"
              />
            </div>
            <div>
              <div
                className={`d-flex justify-content-end align-items-md-center align-items-end gap-1 gap-md-3 ${classes.date_time}`}
              >
                <h4 className="text-white">{notification.text}</h4>
                <div className="d-flex justify-content-end align-items-center gap-md-3 gap-1">
                  <p className="text-white">
                    {moment(notification.createdAt).format('hh:mm')}
                  </p>
                  <span className="text-white">|</span>
                  <p className="text-white">
                    {moment(notification.createdAt).format('DD.MM.YYYY')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </a>
      ))}
    </div>
  );
  const unViewedNJsx = (
    <div className={classes.pillbox}>
      {unViewedNotifications.map((notification) => (
        <Link to={notification.url} key={notification._id}>
          <div
            className={`bg-white d-flex justify-content-between align-items-center gap-md-5 gap-3 ${classes.notification_container}`}
          >
            <div className="d-flex ps-md-2 ps-3 justify-content-center align-items-center">
              <img
                src={notification.creator?.profilePicture}
                alt="Notification Creator"
              />
            </div>
            <div>
              <div
                className={`d-flex justify-content-end align-items-md-center align-items-end gap-1 gap-md-3 mb-md-3 mb-1 ${classes.date_time}`}
              >
                <h4>{notification.text}</h4>
                <div className="d-flex justify-content-end align-items-center gap-md-3 gap-1">
                  <p>{moment(notification.createdAt).format('hh:mm')}</p>
                  <span>|</span>
                  <p>{moment(notification.createdAt).format('DD.MM.YYYY')}</p>
                </div>
              </div>
              {notification.type === 'friendRequests' &&
                !notificationsViewed.includes(notification.profile._id) && (
                  <div
                    className={`d-flex justify-content-end align-items-center gap-md-3 gap-2 ${classes.friend_request}`}
                  >
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        addUserToProfile(
                          notification.creator._id,
                          notification.profile,
                          'profileFriends'
                        );
                      }}
                    >
                      הפוך לאדמין
                    </button>
                    <button
                      onClick={(e) => {
                        e.stopPropagation();

                        removeUserToProfile(
                          notification.creator._id,
                          notification.profile,
                          'friendRequests'
                        );
                      }}
                    >
                      דחה
                    </button>
                    <button
                      className={classes.active_btn}
                      onClick={(e) => {
                        e.stopPropagation();
                        addUserToProfile(
                          notification.creator._id,
                          notification.profile,

                          'profileAdmins'
                        );
                      }}
                    >
                      אשר
                    </button>
                  </div>
                )}
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
  /* For accordion image rotate */
  const [collapseOne, setCollapseOne] = useState(true);
  const [collapseTwo, setCollapseTwo] = useState(true);
  const handleAccordion = (acc_id) => {
    acc_id === 'collapseOne'
      ? setCollapseOne((prev) => !prev)
      : setCollapseTwo((prev) => !prev);
  };

  return (
    <div className={`${className} row`}>
      <div className={`${!modal && 'col-lg-8 col-md-10 mx-auto'}`}>
        {modal ? (
          <React.Fragment>
            <h3 className="pb-3">התראות חדשות</h3>
            {unViewedNJsx}
            <h3 className="pb-3">התראות קודמות</h3>
            {viewedNfJsx}
            <Link
              to="/notifications"
              className={classes.see_more}
              onClick={() => dispatch(hideModal('notificationModal'))}
            >
              לחץ לעמוד התראות המלא
            </Link>
          </React.Fragment>
        ) : (
          <div className="accordion" id="accordionExample">
            <div className="accordion-item bg-transparent border-0">
              <div
                className={`accordion-header border-0 ${classes.accordion_header}`}
                id="headingOne"
                onClick={() => handleAccordion('collapseOne')}
              >
                <button
                  className="accordion-button d-block"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h3>
                    <img
                      src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676727612/Arrow_2_rs30zy.png"
                      alt="arrow"
                      className="text-center me-2"
                      style={{
                        transform: collapseOne
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                    />
                    התראות חדשות
                  </h3>{' '}
                </button>
              </div>
              <div
                id="collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="headingOne"
              >
                <div className="accordion-body bg-transparent">
                  {unViewedNJsx}
                </div>
              </div>
            </div>
            <div className={`accordion-item bg-transparent border-0 mt-5`}>
              <div
                className={`accordion-header border-0 ${classes.accordion_header}`}
                id="headingTwo"
                onClick={() => handleAccordion('collapseTwo')}
              >
                <button
                  className="accordion-button collapsed d-block"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h3>
                    <img
                      src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1676727612/Arrow_2_rs30zy.png"
                      alt="arrow"
                      className="text-center me-2"
                      style={{
                        transform: collapseTwo
                          ? 'rotate(180deg)'
                          : 'rotate(0deg)',
                      }}
                    />
                    התראות קודמות
                  </h3>{' '}
                </button>
              </div>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse show"
                aria-labelledby="headingTwo"
              >
                <div className="accordion-body">
                  <InfiniteScroll
                    dataLength={viewedNotifications.length}
                    next={fetchMoreData}
                    hasMore={totalViewedNf > viewedNotifications.length}
                    loader={
                      <p className={classes.loading_text}>טוען התראות ישנות</p>
                    }
                    endMessage={
                      <p className={classes.loading_text}>כל ההתראות נטענו</p>
                    }
                  >
                    {viewedNfJsx}
                  </InfiniteScroll>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
