import { createSlice, createAsyncThunk, isAnyOf } from '@reduxjs/toolkit';
import lifeAxios from '../../utils/lifeAxios';

/* Initial State for notification */
const initialState = {
  notifications: [],
  loading: false,
  error: '',
};
export const getMyNotifications = createAsyncThunk(
  'notification/getMyNotifications',
  async (_, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).get(
        `/notifications/my-notifications`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const createNotification = createAsyncThunk(
  'notification/createNotification',
  async (formData, { rejectWithValue }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).post(
        `/notifications`,
        formData
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const makeNotificationsViewed = createAsyncThunk(
  'notification/makeNotificationsViewed',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/notifications/mark-viewed`
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const makeFrNfViewed = createAsyncThunk(
  'notification/makeFrNfViewed',
  async (profile, { rejectWithValue, dispatch }) => {
    try {
      const response = await lifeAxios(localStorage.getItem('jwt')).patch(
        `/notifications/mark-fr-viewed`,
        { profile }
      );
      return response.data.data.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);
const notification = createSlice({
  name: 'notification',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMyNotifications.fulfilled, (state, action) => {
      state.notifications = action.payload;
    });
    builder.addMatcher(
      isAnyOf(
        getMyNotifications.pending,
        createNotification.pending,
        makeNotificationsViewed.pending,
        makeFrNfViewed.pending
      ),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getMyNotifications.fulfilled,
        createNotification.fulfilled,
        makeNotificationsViewed.fulfilled,
        makeFrNfViewed.fulfilled
      ),
      (state) => {
        state.loading = false;
      }
    );
    builder.addMatcher(
      isAnyOf(
        getMyNotifications.rejected,
        createNotification.rejected,
        makeNotificationsViewed.rejected,
        makeFrNfViewed.rejected
      ),
      (state, action) => {
        state.loading = true;
        state.error = action.payload;
      }
    );
  },
});

export default notification.reducer;
