import React from 'react';
import classes from './AddressFormModal.module.css';
import cross from '../assets/candleflower-modal-img/x.png';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../features/modal/modalSlice';

const AddressFormModal = ({ register, onSubmit, handleSubmit, type }) => {
  const { addressModal } = useSelector((state) => state.modal);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <Modal
        show={addressModal}
        onHide={() => dispatch(hideModal('addressModal'))}
        size="lg"
        centered
      >
        <Modal.Header style={{ border: 0, background: '#1A4E74' }}>
          <h5 className={`${classes.modal_title} m-auto`} dir="rtl">
            כתובת משלוח ל-QR שלכם!
          </h5>
          <button
            type="button"
            className={`${classes.btn_close}  btn align-self-start`}
            onClick={() => dispatch(hideModal('addressModal'))}
          >
            <img src={cross} alt="Close" />
          </button>
        </Modal.Header>
        <Modal.Body className={classes.modal_body}>
          <div className={`row ${classes.input_container}`}>
            <div className="col-md-8 col-12 m-auto">
              <div className="row">
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=" *שם מלא"
                      {...register('fullName')}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*עיר"
                      {...register('address')}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className={classes.input_item}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="*מס' טלפון"
                      {...register('phone2')}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.address_form_text}>
            <p>
              יש להזין את כתובת המשלוח אליה תרצו לקבל את הברקודים החדשים שהזמנתם
              ואת פרטי יצירת הקשר עם האדם שיקבל את החבילה.
            </p>
          </div>
          <div className={`${classes.button_container}`}>
            <button
              onClick={(e) => handleSubmit((data) => onSubmit(data, type))(e)}
            >
              להמשך ורכישה
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default AddressFormModal;
