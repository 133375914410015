import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import classes from './OrganizationSlider.module.css';
import { Autoplay } from 'swiper';
import { Link } from 'react-router-dom';

const OrganizationSlider = () => {
  return (
    <section className={classes.organization_slider}>
      <Swiper
        slidesPerView={3}
        spaceBetween={20}
        centeredSlides={true}
        className="mySwiper"
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          768: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 10,
            spaceBetween: 20,
          },
        }}
        modules={[Autoplay]}
      >
        <SwiperSlide>
          <Link
            to="/organization-profile/63ce4f9dcadfc7b0e2918a97"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/lalag_1_xin3md.jpg"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/6395bb5a8b5e1a0f3a2006ef"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/oketz_1_nkyyk7.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/63ad82e27dd144ac5ed534f7"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/yahalom_1_nwizp4.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/63453063fb349a88e822e61c"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/551_1_heejmz.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/638f6e92a7efd9d042189ebb"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686379/646_1_jkprmt.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/62baf5cff10da082cb5d58b4"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686379/atalef_1_o6eorr.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/632073ce64e41822e80c005b"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686379/bazelet_1_tr9tah.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/6354c301cec7be578739b0ba"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686379/duv_1_xpvpvj.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/63aaa368f770ee8e12f33e88"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/egoz_1_d932xu.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide>
          <Link
            to="/organization-profile/63808de50a6114a5a426687e"
            className={`${classes.img_container} ${classes.stretch}`}
          >
            <img
              src="https://res.cloudinary.com/lifecloud-qr/image/upload/v1675686377/etzion_1_m4buqa.png"
              alt="organization"
            />
          </Link>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default OrganizationSlider;
