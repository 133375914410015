import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/footer-img/logo.png';
import classes from './Footer.module.css';

const Footer = ({ className }) => {
  return (
    <footer className={`${classes.footer} ${className}`}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-2  col-xl-3">
            <div className="footer-logo-container text-lg-start text-center">
              <Link to="/">
                <img
                  className="life-cloud-logo-img-home img-fluid "
                  src={logo}
                  alt="logo"
                />
              </Link>
            </div>
          </div>
          <div className="col-lg-6 col-xl-6 ">
            <div className={classes.footer_links}>
              <Link to="/qa">
                <span>שאלות ותשובות</span>
              </Link>
              <span className={`${classes.first} ${classes.line_separator}`}>
                |
              </span>
              <Link to="/policy">
                <span>מדיניות פרטיות</span>
              </Link>
              <span className={`${classes.second} ${classes.line_separator}`}>
                |
              </span>
              <Link to="/regulations">
                <span>תקנון</span>
              </Link>
              <span className={`${classes.third} ${classes.line_separator}`}>
                |
              </span>
              <Link to="/about">
                <span>אודות</span>
              </Link>
              <span className={`${classes.fourth} ${classes.line_separator}`}>
                |
              </span>

              <Link to="/contact">
                <span>צרו קשר</span>
              </Link>
              <span className={`${classes.fifth} ${classes.line_separator}`}>
                |
              </span>
              <Link to="/contact">
                <span>ביטול עסקה</span>
              </Link>
            </div>
          </div>
          {/* footer copyright */}
          <div className="col-lg-4 col-xl-3 text-lg-end text-center">
            <p className={classes.c_rights_reserved} dir="rtl">
              (C) כל הזכויות שמורות לחיים בענן
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
