import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Login from './pages/Auth/Login';
import Registration from './pages/Auth/Registration';
import Notification from './pages/Notification/Notification';
import Plans from './pages/Plans/Plans';
import Contact from './pages/Contact/Contact';
import { getMe } from './features/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import MemoryDetails from './pages/MemoryDetails/MemoryDetails';
import CreateMemory from './pages/CreateMemory/CreateMemory';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import AllModals from './pages/AllModals/AllModals';
import Verification from './pages/Verification/Verification';
import NotFound from './pages/Error/404/404';
import ServerError from './pages/Error/505/505';
import ProfileDetails from './pages/ProfileDetails/ProfileDetails';
import OrganizationProfileDetails from './pages/OrganizationProfileDetails/OrganizationProfileDetails';
import UserProfile from './pages/UserProfile/UserProfile';
import OrganizationUserProfile from './pages/OrganizationUserProfile/OrganizationUserProfile';
import Regulations from './pages/AccordionPage/Regulations';
import Policy from './pages/AccordionPage/Policy';
import QA from './pages/AccordionPage/QA';
import DropsOfMemory from './pages/DropsOfMemory/DropsOfMemory';
import SearchResult from './pages/SearchResult/SearchResult';
import { PrivateRoute } from './utils/PrivateRoute';
import ProfileCreate from './pages/Profile/ProfileCreate';
import ProfileEdit from './pages/Profile/ProfileEdit';
import OrganizationProfileCreate from './pages/OrganizationProfileCreate/OrganizationProfileCreate';
import OrganizationRegistration from './pages/OrganizationRegistration/OrganizationRegistration';
import OrganizationProfileEdit from './pages/OrganizationProfileCreate/OrganizationProfileEdit';
import LightCF from './pages/LightCF/LightCF';
import PaymentStatus from './pages/PaymentStatus/PaymentStatus';
import RedirectToProfile from './utils/RedirectToProfile';
import EditMemory from './pages/EditMemory/EditMemory';
import Blog from './pages/Blog/Blog';
import BlogPost from './pages/Blog/BlogPost';
import QrLanding from './pages/QrLanding/QrLanding';
import CreateBlog from './pages/CreateBlog/CreateBlog';
import EditBlog from './pages/EditBlog/EditBlog';
function App() {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/registration"
          element={user._id ? <Navigate to="/" replace /> : <Registration />}
        />
        <Route
          path="/login"
          element={user._id ? <Navigate to="/" replace /> : <Login />}
        />
        <Route
          path="/organization-registration"
          element={<OrganizationRegistration />}
        />
        <Route path="/plans" element={<Plans />} />
        <Route path="/about" element={<About />} />
        <Route path="/notifications" element={<Notification />} />
        <Route path="/memory/:memoryId" element={<MemoryDetails />} />
        <Route
          path="/virtual-memory/:profileId"
          element={
            <PrivateRoute>
              <DropsOfMemory />
            </PrivateRoute>
          }
        />
        <Route
          path="/create-memory/:profileId"
          element={
            <PrivateRoute>
              <CreateMemory />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-memory/:memoryId"
          element={
            <PrivateRoute>
              <EditMemory />
            </PrivateRoute>
          }
        />
        <Route path="/search/:searchValue" element={<SearchResult />} />
        <Route
          path="/organization-create-profile"
          element={<OrganizationProfileCreate />}
        />
        <Route
          path="/profile/:profileId/:profileName"
          element={<ProfileDetails />}
        />
        <Route path="/profile/:profileId" element={<ProfileDetails />} />
        <Route
          path="/profiledetails/:profileId"
          element={<RedirectToProfile />}
        />
        <Route
          path="/organization-profile/:profileId/:profileName"
          element={<OrganizationProfileDetails />}
        />
        <Route
          path="/organization-profile/:profileId"
          element={<OrganizationProfileDetails />}
        />
        <Route
          path="/create-profile"
          element={
            <PrivateRoute>
              <ProfileCreate />
            </PrivateRoute>
          }
        />
        <Route
          path="/light-candle-flower"
          element={
            <PrivateRoute>
              <LightCF />
            </PrivateRoute>
          }
        />
        <Route
          path="/edit-profile/:profileId"
          element={
            <PrivateRoute>
              <ProfileEdit />
            </PrivateRoute>
          }
        />{' '}
        <Route
          path="/edit-organization-profile/:profileId"
          element={
            <PrivateRoute>
              <OrganizationProfileEdit />
            </PrivateRoute>
          }
        />
        <Route path="/user-profile" element={<UserProfile />} />
        <Route
          path="/organization-user-profile"
          element={<OrganizationUserProfile />}
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/modals" element={<AllModals />} />
        <Route path="/" element={<Home />} />
        <Route path="/verify-email/:token" element={<Verification />} />
        <Route path="/regulations" element={<Regulations />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/qa" element={<QA />} />
        <Route path="/create-blog" element={<CreateBlog />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogId" element={<BlogPost />} />
        <Route path="/edit-blog/:blogId" element={<EditBlog />} />
        <Route path="/qr-landing" element={<QrLanding />} />
        {/* Contact */}
        <Route path="/contact" element={<Contact />} />
        {/* Payment success & error page */}
        <Route path="/process/:status" element={<PaymentStatus />} />
        {/* 505 & 404 page */}
        <Route path="/505" element={<ServerError />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </React.Fragment>
  );
}
export default App;
